import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'situacao'
})
export class SituacaoPipe implements PipeTransform {

  transform(value: number): string {
    return value === 2 ? "IMUNIZADO" : "PENDENTE";
  }

}
