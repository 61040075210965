import { VacinaService } from './../../vacina.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public conta = this.fb.group({
    login: this.fb.control('', [Validators.required]),
    senha: this.fb.control('', [Validators.required])
  });
  public recaptcha_key: string = environment.recaptcha;
  public disableButton = true;
  private recaptcha_code: string;

  public erro: string;

  constructor(
    private fb: FormBuilder,
    private vacinaService: VacinaService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  public login(): void {
    if (this.conta.valid) {
      this.erro = null;
      const dados: any = this.conta.value;
      dados.recaptcha = this.recaptcha_code;

      this.vacinaService.login(dados).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("vacina-token", res.data);
          this.router.navigate(['/']);
        }
      }).catch((err) => {
        if (err) {
          this.erro = err.data ?? 'Usuário não encontrado com as informações inseridas';
        }
      });
    }
  }

  public recaptcha($event): void {
    if ($event) {
      this.recaptcha_code = $event;
      this.disableButton = false;
    }
  }

}
