import { MediunidadePipe } from './../../mediunidade.pipe';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AxiosResponse } from 'axios';
import { VacinaService } from 'src/app/vacina.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListaComponent implements OnInit, AfterViewInit {

  public displayedColumns: string[] = ['nome', 'idade', 'mediunidade', 'situacao', 'cartao'];
  public displayedColumnsSmall: string[] = ['nome', 'mediunidade'];
  public vacinados: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public expandedElement: any = 'expanded';

  constructor(
    private vacinaService: VacinaService,
    private router: Router,
    private mediunidadePipe: MediunidadePipe
  ) {
    this.load();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.vacinados.paginator = this.paginator;
    this.vacinados.sort = this.sort;
  }

  private load() {
    const vacinados_cache = localStorage.getItem('vacinados-nerano');
    if (vacinados_cache) {
      this.vacinados.data = JSON.parse(vacinados_cache);
      // this.vacinados.data.forEach((element: any) => {
      //     console.log(this.mediunidadePipe.transform(element.mediunidade));
      // });
    } else {
      this.refresh();
    }
  }

  public refresh(): void {
    this.vacinados.data = [];
    this.vacinaService.listar().then((res: AxiosResponse) => {
      if (res.status === 200) {
        this.vacinados.data = res.data;
        localStorage.setItem('vacinados-nerano', JSON.stringify(this.vacinados));
      }
    }).catch((err) => {
      if (err) {
        // this.sair();
      }
    });
  }

  public sair(): void {
    localStorage.clear();
    this.vacinados.data = [];
    this.router.navigate(['/login']);
  }

  public abrir(cartao_url: string): void {
    window.open(cartao_url, '_blank');
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.vacinados.filter = filterValue.trim().toLowerCase();

    if (this.vacinados.paginator) {
      this.vacinados.paginator.firstPage();
    }
  }

}
