<div class="lista-container">
  <mat-toolbar color="primary">
    <span>CADASTRO NERANO</span>
    <span class="spacer"></span>
    <button mat-icon-button class="icon favorite-icon" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
    <button mat-icon-button class="icon" (click)="sair()">
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar>

  <div class="lista-content big">
    <div class="contagem"><b>{{ vacinados.data.length }}</b> mestres registrados</div>
    <mat-form-field appearance="standard" class="full">
      <mat-label>Filtrar Mestre</mat-label>
      <input matInput (keyup)="applyFilter($event)" autocomplete="off" #input>
    </mat-form-field>
    <table mat-table [dataSource]="vacinados" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.nome_completo}} </td>
      </ng-container>
      <ng-container matColumnDef="idade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Idade </th>
        <td mat-cell *matCellDef="let element"> {{element.dt_nasc | idade}} </td>
      </ng-container>
      <ng-container matColumnDef="mediunidade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Med. </th>
        <td mat-cell *matCellDef="let element"> <div innerHtml="{{element.mediunidade | mediunidade}}"></div> </td>
      </ng-container>
      <ng-container matColumnDef="situacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
        <td mat-cell *matCellDef="let element"> {{element.situacao | situacao}} </td>
      </ng-container>
      <ng-container matColumnDef="cartao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cartão </th>
        <td mat-cell *matCellDef="let element"> <mat-icon (click)="abrir(element.cartao_url)">open_in_new</mat-icon></td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">Nenhum resultado encontrado de "{{input.value}}"</td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página de usuários"></mat-paginator>
    <br />
  </div>



  <div class="lista-content small">
    <div class="contagem"><b>{{ vacinados.data.length }}</b> mestres registrados</div>

    <mat-form-field appearance="standard" class="full">
      <mat-label>Filtrar Mestre</mat-label>
      <input matInput (keyup)="applyFilter($event)" autocomplete="off" #input>
    </mat-form-field>
    <table mat-table
          [dataSource]="vacinados" multiTemplateDataRows
          class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumnsSmall">
      <th mat-header-cell *matHeaderCellDef> {{ column === 'nome' ? 'Nome' : 'Med.' }} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column === 'nome'">{{  element['nome_completo'] }}</span>
        <div *ngIf="column === 'mediunidade'" innerHtml="{{element.mediunidade | mediunidade}}"></div>
      </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsSmall.length">
        <div class="element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="element-diagram">
            <p> {{element.nome_completo}} </p>
            <p> {{ element.mediunidade === 'A' ? 'Apará' : 'Doutrinador' }} </p>
            <p> {{element.dt_nasc | idade}} </p>
            <p>
              <mat-icon (click)="abrir(element.cartao_url)">open_in_new</mat-icon>
            </p>
          </div>
        </div>
      </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSmall"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumnsSmall;"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página de usuários"></mat-paginator>
    <br />
  </div>

</div>
