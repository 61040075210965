import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

@Injectable({
  providedIn: 'root'
})
export class VacinaService {

  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: environment.api
    });
  }

  public login(dados: any): Promise<AxiosResponse> {
    return this.instance.post('/vacina/login', dados);
  }

  public listar(): Promise<AxiosResponse> {
    return this.instance.get('/vacina/listar', {
      headers : {
        'Authorization' : localStorage.getItem('vacina-token')
      }
    });
  }



}
