<div class="login-container">
  <div class="login-logo">
    <img src="/assets/images/jaguar-logo.png" alt="Nerano do Amanhecer" />
  </div>
  <form [formGroup]="conta">
    <mat-card>
      <mat-card-header>
          <mat-card-title>LOGIN</mat-card-title>
          <mat-card-subtitle>Cadastro de Mestres Vacinados</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Login</mat-label>
          <input type="text" matInput formControlName="login">
          <mat-error *ngIf="conta.controls.login.hasError('required')">
            Campo Login é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input type="password" matInput formControlName="senha">
          <mat-error *ngIf="conta.controls.senha.hasError('required')">
            Campo Senha é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <re-captcha
          (resolved)="recaptcha($event)"
          [siteKey]="recaptcha_key"
        ></re-captcha>
        <div class="erro" *ngIf="erro">
          <span>{{ erro }}</span>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
          <button mat-raised-button color="primary" (click)="login()" [disabled]="conta.invalid || disableButton">ENTRAR</button>
      </mat-card-actions>
      <mat-card-footer>
          <span class="center">Criado pela falange dos Príncipes Maya - Nerano</span>
      </mat-card-footer>
    </mat-card>
  </form>
</div>
