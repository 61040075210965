import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediunidade'
})
export class MediunidadePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    let span = "<span class='mediunidadetipo";
    if (value === "A") {
      span += " apara' matTooltip='Médium Apará' alt='Apará'>A<span>";
    } else {
      span += " doutrinador' matTooltip='Médium Doutrinador' alt='Doutrinador'>D<span>";
    }
    return span;
  }

}
