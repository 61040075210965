import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { ListaComponent } from './views/lista/lista.component';
import { LoginComponent } from './views/login/login.component';
import { SituacaoPipe } from './situacao.pipe';
import { IdadePipe } from './idade.pipe';

const routes: Routes = [
  {
    path: '',
    component: ListaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
