import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idade'
})
export class IdadePipe implements PipeTransform {

  transform(value: string): string {
    const timeDiff = Math.abs(Date.now() - Date.parse(value));
    return Math.floor((timeDiff / (1000 * 3600 * 24))/365) + ' anos';
  }

}
